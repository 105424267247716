import React, { useState } from "react";
import { Button, TextField, Box, Snackbar } from "@mui/material";
import API_ENDPOINT from "./CONSTANTS";

function JokeForm({ onAddJoke }) {
  const [joke, setJoke] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleSubmit = (event) => {
    event.preventDefault();
    const email = localStorage.getItem('email'); // retrieve the email from localStorage
    // extract the username from the email
    const username = email.substring(0, email.lastIndexOf("@"));
    

    // Make sure email exists before sending it
    if (!email) {
      setSnackbarMessage('No email found');
      setSnackbarOpen(true);
      return;
    }

    fetch(API_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ joke, username }) // Include the email in the body of the request
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
        localStorage.setItem("joke", joke); // Save the joke to localStorage
        onAddJoke(joke);
        setJoke('');
        setSnackbarMessage('Joke submitted successfully');
        setSnackbarOpen(true);
      })
    .catch(error => {
        console.error(error);
        setSnackbarMessage('Error submitting joke');
        setSnackbarOpen(true);
      });
  };



  const handleChange = (event) => {
    setJoke(event.target.value);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Box 
          display="flex" 
          flexDirection="column" 
          justifyContent="center" 
          alignItems="center"
          mb={4}
          width="100%"
        >
          <TextField
            value={joke}
            onChange={handleChange}
            placeholder="Enter a joke"
            variant="outlined"
            multiline
            rows={4}
            style={{ marginBottom: "16px", width: "100%" }}
          />
          <Button type="submit" variant="contained" color="primary">
            Add Joke
          </Button>
        </Box>
      </form>
      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={6000} 
        onClose={handleSnackbarClose} 
        message={snackbarMessage} 
     
      />
    </div>
  );
}

export default JokeForm;
