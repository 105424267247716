import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { signInWithGoogle, auth } from "./Firebase";
import { signOut } from "firebase/auth";
import GoogleButton from 'react-google-button'



function Navbar({ onSignIn,onSignOut }) {

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setUserName('');
        // clear local storage
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        
        onSignOut();
        
        console.log("User signed out successfully");
      })
      .catch((error) => {
        console.log("Error signing out:", error);
      });
  };

  const [userName, setUserName] = React.useState(() => localStorage.getItem('name') || '');

  React.useEffect(() => {
    localStorage.setItem('name', userName);
  }, [userName]);

  // Call the onSignIn function after setting the username
  const handleSignIn = (userName) => {
    setUserName(userName);
    localStorage.setItem('name', userName);
    onSignIn(userName); // New line
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', bgcolor: 'primary.main', color: 'primary.contrastText',  width: '100%', margin: '0 auto' }}>
      <AppBar position="static">
        <Container maxWidth="lg">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ mr: 'auto' }}>
              Nepali Jokes
            </Typography>
            <Box>
              {userName ? (
                <Button color="inherit" onClick={handleSignOut}>Sign Out</Button>
              ) : (
               
                <GoogleButton onClick={() => signInWithGoogle(handleSignIn)}/>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}

export default Navbar;
