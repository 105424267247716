import styled from '@emotion/styled';
import { useState } from 'react';
import { FaHeart } from 'react-icons/fa';


const JokeContainer = styled.div`display: flex;
  align-items: center;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 1rem;
  word-wrap: break-word;
`;


const JokeContent = styled.div`
  flex: 1;
`;

const JokeText = styled.div`
  margin-bottom: 0.5rem;
`;

const PostedBy = styled.div`
  font-size: 12px;
  color: gray;
  margin-bottom: 0.3rem;
`;



const HeartIcon = styled(FaHeart)`
  margin-right: 0.5rem;
`;




const HeartButton = styled.button`
  padding: 0.5rem;
  background-color: #ff7f7f; // Feel free to adjust the color
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;


const Joke = ({ joke, jokeId, postedBy }) => {
  const [votes, setVotes] = useState(0);
  const email = localStorage.getItem('email');
  const isLoggedIn = !!email; // Check if email exists in localStorage

  const handleUpvote = () => {
    if (!isLoggedIn) {
      // Handle the case when the user is not logged in
      console.log('User is not logged in');
      return;
    }

    const userName = email.substring(0, email.lastIndexOf("@"));

    const requestBody = {
      jokeId: jokeId,
      userId: userName
    };

    fetch(`https://curtz84nt4.execute-api.us-east-1.amazonaws.com/dev/jokes/${jokeId}/upvote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    })
      .then(response => {
        if (response.ok) {
          console.log('Joke upvoted successfully');
          setVotes(votes + 1);
        } else {
          console.error('Failed to upvote joke');
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <JokeContainer>
      <JokeContent>
        <PostedBy>Posted by: {postedBy}</PostedBy>
        <JokeText>{joke}</JokeText>
        <div>{votes} {votes > 0 ? '❤️' : '♥'} </div>  {/* Display the vote count */}
      </JokeContent>
      {isLoggedIn && (
        <HeartButton onClick={handleUpvote}>
          <HeartIcon />
        </HeartButton>
        )}
    </JokeContainer>
    );
};

export default Joke;