import React, { useEffect, useState } from 'react';
import JokeForm from './JokeForm';
import Jokes from './Jokes';
import ResponsiveAppBar from './navbar';

export default function App() {
  const [userName, setUserName] = useState("");
  const [jokes, setJokes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedName = localStorage.getItem('name');
    setUserName(storedName);

    fetch('https://curtz84nt4.execute-api.us-east-1.amazonaws.com/dev/jokes')
      .then(response => response.json())
      .then(data => {
        setJokes(data.map(item => ({ jokeId: item.jokeId, joke: item.joke, postedBy: item.username })));
        setLoading(false);
      })
      
      .catch(error => {
        console.error('Error:', error);
        setLoading(false);
      });
  }, []);

  const onAddJoke = (joke) => {
    setJokes(oldJokes => [...oldJokes, { joke, postedBy: userName }]);
  };

  const handleSignIn = (userName) => {
    setUserName(userName);
  };

  const handleSignOut = () => {
    setUserName('');
  };

  return (
    <div
      className="App"
      style={{
        backgroundColor: '#f2f2f2',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        minHeight: '100vh',
      }}
    >
      <ResponsiveAppBar onSignIn={handleSignIn} onSignOut={handleSignOut} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '80%',
          maxWidth: '600px',
          alignSelf: 'center',
          margin: '1rem',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {userName ? (
          <div style={{ width: '100%' }}>
            <h4>Welcome, {userName}</h4>
            <JokeForm onAddJoke={onAddJoke} />
          </div>
        ) : (
          <div style={{ fontSize: '1.5rem', textAlign: 'center' }}>
            Sign in to submit a joke.
          </div>
        )}
        
        {loading ? (
          <div>Loading...</div> 
        ) : (
          <div>
            <h2 style={{ textAlign: 'center' }}>Jokes</h2>
            {jokes.map((joke, index) => (
              <div
                key={index}
                style={{

                }}
              >
                <Jokes key={index} jokeId={joke.jokeId} joke={joke.joke} index={index} postedBy={joke.postedBy} userName={userName} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
