import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDCZqGiCujrDgK0NKU22f0BirAtbYtPbT4",
  authDomain: "nepalilogin.firebaseapp.com",
  projectId: "nepalilogin",
  storageBucket: "nepalilogin.appspot.com",
  messagingSenderId: "151244789165",
  appId: "1:151244789165:web:a0e5e1355241d59a483d24",
  measurementId: "G-BBFG2GPYEV"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const provider = new GoogleAuthProvider();

export const signInWithGoogle = (callback) => {
  signInWithPopup(auth, provider)
    .then((result) => {
      const name = result.user.displayName;
      const email = result.user.email;
      const profilePic = result.user.photoURL;

      localStorage.setItem("name", name);
      localStorage.setItem("email", email);
      localStorage.setItem("profilePic", profilePic);

      callback(name);  // call the callback function with the signed-in user's name
    })
    .catch((error) => {
      console.log(error);
    });
};





